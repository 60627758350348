import "./ContactSection.scss"

import React from "react"
import { Container } from "reactstrap"

export default ({ data: { title, html, cta, ctaUrl } }) => {
  return (
    <section className="section-contact bg-blue-dark">
      <Container className="py-5">
        <div className="contact-section-grid py-lg-4">
          <div className="contact-section-flex-item-1">
            <h4 className="text-white dot dot-yellow">{title}</h4>
          </div>
          <div className="contact-section-flex-item-2">
            <div
              className="text-white"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
            <div className="button-container">
              <a
                href={ctaUrl}
                className="btn btn-sm btn-light bg-white text-blue-dark font-weight-bold"
              >
                {cta}
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
