import React from "react"
import { graphql } from "gatsby"
import { getEdgesForQuery, getQueryData, getSectionDataFor } from "@utils/utils"
import { compose } from "ramda"
import { Helmet } from "react-helmet"
/**
 * import Sections
 */

import MainLayout from "@layouts/MainLayout/MainLayout"
import SEO from "@components/shared/SEO/SEO"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"
import ContactSection from "@components/shared/ContactSection/ContactSection"
import ServicesSection from "@components/index/ServicesSection/ServicesSection"
import TechnologiesSection from "@components/index/TechnologiesSection/TechnologiesSection"
import AboutSection from "@components/index/AboutSection/AboutSection"

/**
 * import pictures
 */

import imageAbout from "@assets/pictures/teaser_ueber-uns.svg"
import imageServices from "@assets/pictures/teaser_leistungen.svg"
import imageHeader from "@assets/pictures/buehne_startseite.svg"
import imageTechnologies from "@assets/pictures/teaser_technologien.svg"

/**
 * quering data
 */

export const query = graphql`
  query HeaderSectionIndexQuery {
    content: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/index" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
            cta
            ctaUrl
          }
          internal {
            content
          }
          html
        }
      }
    }
    footer: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/ui" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            section
            title
            subtitle
            cta
            ctaUrl
          }
          internal {
            content
          }
          html
        }
      }
    }
  }
`

const IndexPage =  ({ data }) => {
  const content = compose(getQueryData, getEdgesForQuery("content"))(data)
  const headerSectionData = getSectionDataFor("index-section-header", content)
  const servicesSectionData = getSectionDataFor(
    "index-section-services",
    content
  )
  const technologiesSectionData = getSectionDataFor(
    "index-section-technologies",
    content
  )
  const aboutSectionData = getSectionDataFor("index-section-about", content)
  
  const footer = compose(getQueryData, getEdgesForQuery("footer"))(data)
  const contactSectionData = getSectionDataFor(
    "ui-section-footer-contact",
    footer
  )

  return (
    <>
      <Helmet>
        <title>IT&I Software GmbH</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      </Helmet>
      <MainLayout>
        <SEO />
        <HeaderSection data={headerSectionData} image={imageHeader} />
        <ServicesSection data={servicesSectionData} image={imageServices} />
        <TechnologiesSection
          data={technologiesSectionData}
          image={imageTechnologies}
        />
        <AboutSection data={aboutSectionData} image={imageAbout} />
        <ContactSection data={contactSectionData} />
      </MainLayout>
    </>
  )
}


export default IndexPage
