import React from "react"
import GridSection from "@components/shared/GridSection/GridSection"
import { Link } from "gatsby"

export default ({ data: { subtitle, content, cta, ctaUrl }, image }) => (
  <GridSection
    anchor={"section-technologies"}
    className="bg-blue-bright switch-on-collapse"
  >
    <div className="section-grid-copy">
      <h3 className="display-3 mb-0 font-weight-bolder dot dot-yellow">
        {subtitle}
      </h3>
      <p className="mt-3 mb-5">{content}</p>
      <Link to={ctaUrl} className="btn btn-dark btn-sm">
        {cta}
      </Link>
    </div>
    <div className="section-grid-media">
      <img alt={subtitle} src={image}></img>
    </div>
  </GridSection>
)
